export function ResearchInnovation() {
  return (
    <div className="my-12 mx-8  md:mx-16 lg:mx-24 xl:mx-36">
      <div className="max-w-[1440px] mx-auto">
        <h1 className="text-center text-2xl md:text-4xl my-2 md:my-4 text-[#003060] dark:text-[#227C9D] font-bold">
          Research And Innovation
        </h1>
        <h2 className=" mt-10 text-2xl md:text-2xl my-2 md:my-4 text-[#696969] font-bold">
          Research, Consultancy and Extension
        </h2>
        <p className="  mt-5 my-1 text-md md:text-lg dark:text-white">
          The departments of English, Political Science, History, Hindi,
          Punjabi, Economics, Biology and Chemistry have a number of
          publications to their credit. Research supervision to M.Phil students
          has been provided by different Departments and three of the teachers
          have undertaken Minor Research Projects from UGC. Many teachers have
          published books, articles, research papers, abstracts etc. and have
          presented papers in various conferences.Faculty members provide
          regular consultancy services to other educational institutions.
        </p>
        <p className=" mt-7 my-1 text-md md:text-lg dark:text-white">
          Publications of research papers, articles, books and creative writing
          by the faculty members is commendable. The college is extremely active
          in the field of extension services and is also very responsive to
          community needs. Various extension and awareness programmes are being
          regularly conducted by the college such as AIDS awareness, female
          foeticide, Sanitation, Literacy campaign, Traffic Management, Pulse
          Polio Campaigns, Blood Donation Camps etc. The College has
          collaborated with various Government and Non-Government agencies to
          undertake these programmes. The faculty members have been given
          additional charges of organizing various extension services such as
          NSS, N.C.C, Youth Welfare Club, Red Cross, Green Brigade Units and
          Alumni Association. All these out reach activities are planned and
          carried out under the overall supervision of the Dean, Extension
          Activities. The college works and plans extension activities in
          collaboration with NGOs like Lions Club, Rotary Club, Village
          Panchayat, Municipal Committee, Health Department, Social Welfare
          Department and Sports Clubs.
        </p>
        <p className="  mt-7 my-1 text-md md:text-lg dark:text-white">
          The college is actively responsive to the natural calamities and
          sufferings in the country. At the time of earthquake in Maharashtra
          and Gujarat, Cyclone in Orissa, Flood in Panjab and Railway accident
          near Khanna and Kargil war, the college raised funds and other
          material aids for the victims of these tragedies. For these exemplary
          social services, the Honble Governor of Panjab honoured the college
          with a medal and a certificate. The Green Brigade of the college has
          planted a large number of trees in and around the college campus.{" "}
        </p>
        <h2 className=" mt-10 text-2xl md:text-2xl my-2 md:my-4 text-[#696969] font-bold">
          Some Innovations snd Researches links done in A.S College:
        </h2>
        <ul className="text-black dark:text-white my-1 text-md md:text-lg w-[60vw] shadow-xl bg-secondary2 dark:bg-secondary2_dark px-8 py-10 rounded-md m-auto">
          <li className="mt-5">
            <a
              className="  mt-7 my-1 text-md md:text-lg font-bold hover:text-[#227C9D] dark:text-white dark:hover:text-[#227C9D]"
              href="./Oyster - Final_.pdf"
            >
              Cultivation of Oyster Mushroom
            </a>
          </li>
          <li className="mt-5">
            <a
              className="  mt-7 my-1 text-md md:text-lg font-bold hover:text-[#227C9D] dark:text-white dark:hover:text-[#227C9D]"
              href="./Cultivation of Super Mushroom Cordyceps Militaris_.pdf"
            >
              Cultivation of Super Mushroom Cordyceps Militaris
            </a>
          </li>
          <li className="mt-5">
            <a
              className=" mt-7 my-1 text-md md:text-lg font-bold hover:text-[#227C9D] dark:text-white dark:hover:text-[#227C9D]"
              href="./Experimental Electric Scooter_.pdf"
            >
              Experimental Electric Scooter
            </a>
          </li>
          <li className="mt-5">
            <a
              className=" mt-7 my-1 text-md md:text-lg font-bold hover:text-[#227C9D] dark:text-white dark:hover:text-[#227C9D]"
              href="./Hydroponic System__.pdf"
            >
              Hydroponic System
            </a>
          </li>
          <li className="mt-5">
            <a
              className="  mt-7 my-1 text-md md:text-lg font-bold hover:text-[#227C9D] dark:text-white dark:hover:text-[#227C9D]"
              href="./Leaf-Shredder_.pdf"
            >
              Leaf-Shredder
            </a>
          </li>
          <li className="mt-5">
            <a
              className=" mt-7 my-1 text-md md:text-lg font-bold hover:text-[#227C9D] dark:text-white dark:hover:text-[#227C9D]"
              href="./Ultralight Aircraft Model (ULA)_.pdf"
            >
              Ultralight Aircraft Model (ULA)
            </a>
          </li>
          <li className="mt-5">
            <a
              className="  mt-7 my-1 text-md md:text-lg font-bold hover:text-[#227C9D] dark:text-white dark:hover:text-[#227C9D]"
              href="./Vermicomposting_.pdf"
            >
              Vermicomposting
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
