export function Management() {
  return (
    <div className="my-12 mx-8  md:mx-16 lg:mx-24 xl:mx-36">
      <div className="max-w-[1440px] mx-auto">
        <h1 className="text-center text-2xl md-7 md:text-4xl my-2 md:my-4 text-[#003060] dark:text-[#227C9D] font-bold">
          Organization and Management
        </h1>
      </div>
      <img
        src="management-society.jpg"
        className=" mt-7 m-auto w-[50vw] max-w-[1080px]"
      ></img>
    </div>
  );
}
