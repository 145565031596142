export function PostGrad() {
  return (
    <div className="my-12 mx-8  md:mx-16 lg:mx-24 xl:mx-36">
      <div className="mx-auto">
        <h1 className="mt-5 text-center text-2xl md:text-4xl my-2 md:my-4 text-[#141E27] dark:text-[#227C9D] font-bold">
          Post Graduate
        </h1>
        <div class="relative overflow-x-auto shadow-md rounded-md">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" class="px-6 py-3">
                  Sr No.
                </th>
                <th scope="col" class="px-6 py-3">
                  Course name
                </th>
                <th scope="col" class="px-6 py-3">
                  Course Duration
                </th>
                <th scope="col" class="px-6 py-3">
                  Contact Person
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td class="px-6 py-4">1</td>
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                >
                  M.Com
                </th>

                <td class="px-6 py-4">2 Years</td>
                <td class="px-6 py-4">Dr. K.K Sharma</td>
              </tr>
              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td class="px-6 py-4">2</td>
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                >
                  M.Sc. (Chemistry)
                </th>

                <td class="px-6 py-4">2 Years</td>
                <td class="px-6 py-4">Prof. Ravinderjeet</td>
              </tr>
              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td class="px-6 py-4">3</td>
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                >
                  M.Sc. (Mathematics)
                </th>
                <td class="px-6 py-4">2 Years</td>
                <td class="px-6 py-4">Dr. B.K.Aggarwal</td>
              </tr>
              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td class="px-6 py-4">4</td>
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                >
                  M.A.(English)
                </th>
                <td class="px-6 py-4">2 Years</td>
                <td class="px-6 py-4">Prof. Dinesh</td>
              </tr>
              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td class="px-6 py-4">5</td>
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                >
                  M.A.(History)
                </th>
                <td class="px-6 py-4">2 Years</td>
                <td class="px-6 py-4">Prof Prince Pal</td>
              </tr>
              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td class="px-6 py-4">6</td>
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                >
                  M.A(Pol. Science)
                </th>
                <td class="px-6 py-4">2 Years</td>
                <td class="px-6 py-4">Prof. Gagandeep Sethi</td>
              </tr>
              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td class="px-6 py-4">7</td>
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                >
                  M.A(Economics)
                </th>
                <td class="px-6 py-4">2 Years</td>
                <td class="px-6 py-4">Dr. Shiv Kumar</td>
              </tr>
              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td class="px-6 py-4">8</td>
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                >
                  M.A(Punjabi)
                </th>
                <td class="px-6 py-4">2 Years</td>
                <td class="px-6 py-4">Dr. Harpal Bhatti</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
