export function UGCResources() {
  return (
    <div className="my-12 mx-8  md:mx-16 lg:mx-24 xl:mx-36">
      <div className="max-w-[1440px] mx-auto">
        <img className="m-auto" src="./ugcresourcecenter.jpg" alt="" />
        <h1 className="text-center text-2xl md:text-4xl my-2 md:my-4 text-[#003060] dark:text-[#227C9D] font-bold">
          UGC Network Resource Centre
        </h1>
        <ul
          className="list-disc text-white my-5 text-md md:text-lg 
        bg-gradient-to-b from-primary to-primary2 dark:from-primary_dark dark:to-primary2_dark 
        rounded-md shadow-xl py-6 px-10 md:px-12 lg:px-16"
        >
          <li>
            University Grants Commission has entrusted the College to manage a
            Network Resource Centre for providing updated information regarding
            the activities, programmers and schemes of UGC to the students and
            faculty members. The College has entrusted the responsibility to
            look after this NRC to Department of Public Administration. The UGC
            (NRC) is located in the Main Library of the College.
          </li>
          <li>
            UGC Network Resource Centre provides free internet service to
            students from 09.30 a.m to 04.00 pm. Besides this, the centre has
            arranged the subject-wise list of websites to enable the students to
            prepare their study notes. 50 to 70 students daily visit this centre
            and avails the facility of internet. The library staff also helps
            the un-trained students in surfing the internet.
          </li>
        </ul>
      </div>
    </div>
  );
}
