export function LeaveRules() {
  return (
    <div className="my-12 mx-8  md:mx-16 lg:mx-24 xl:mx-36">
      <div className="max-w-[1440px] mx-auto">
        <h1 className="text-center text-2xl md:text-4xl my-2 md:my-4 text-[#003060] dark:text-[#227C9D] font-bold ">
          Leave Rules
        </h1>
        <ul className="list-disc text-white  my-5 text-md md:text-lg bg-gradient-to-b from-primary to-primary2 dark:from-primary_dark dark:to-primary2_dark rounded-md shadow-xl py-6 px-10 md:px-12 lg:px-16">
          <li>
            Application for leave will always be made on the prescribed form
            obtainable from college office.
          </li>
          <li>
            Prior Sanction for leave must be taken.In case of sickness or an
            emergency,the leave application must be submitted with in a week.
          </li>
          <li>
            Application for sick leave for more than five days will have to be
            supported by a medical certificate.
          </li>
          <li>
            Leave on medical grounds or otherwise does not entitle a student to
            claim benifit of lectures or for appearing in the special test.
          </li>
          <li>
            In the matter of Gazetted Holidays,the college follows the punjab
            university calander/Gazetted Notifications only.A list of Gazetted
            Holidays declared by PU has been printed in the student Identity
            Card.
          </li>
        </ul>
      </div>
    </div>
  );
}
