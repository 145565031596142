export function Stadium() {
  return (
    <div className="my-12 mx-8  md:mx-16 lg:mx-24 xl:mx-36">
      <div className="max-w-[1440px] mx-auto">
        <img className="m-auto md-4" src="./stadium.jpeg" alt="" />
        <br />
        <h1 className="text-center text-2xl md:text-3xl mt-4 md-4 my-2 md:my-2 text-[#003060] dark:text-[#227C9D] font-bold ">
          A.S College Stadium
        </h1>
        <p className="text-black my-1 text-md md:text-lg dark:text-[#dfdfdf] ">
          MADAN GOPAL CHOPRA STADIUM OF A.S. COLLEGE IS ONE OF THE GREENEST
          STADIUMS OF THE AREA. IT HAS BEEN CONSTRUCTED WITH THE HELP OF
          GENEROUS CONTRIBUTIONS FROM THE COLLEGE ALUMNI, FACULTY, PARENTS AND
          THE CITIZENS OF THE AREA. APART FROM COLLEGE SPORTS ACTIVITIES, THE
          STADIUM IS ROUTINELY USED BY DAILY WALKERS AND VETERANS AND IT HAS
          BEEN USED BY THE PUNJAB POLICE TIME TO TIME TO CONDUCT ITS RECRUITMENT
          DRIVE. IT HAS ALSO BEEN USED TO ORGANIZE WORLD KABADDI CUP.
        </p>
      </div>
    </div>
  );
}
