export function NCC() {
  return (
    <div className="my-12 mx-8  md:mx-16 lg:mx-24 xl:mx-36">
      <div className="max-w-[1440px] mx-auto">
        <h1 className="text-center text-2xl md:text-4xl my-2 md:my-4 text-[#003060] dark:text-[#227C9D] font-bold">
          NCC
        </h1>
        <h2 className="text-center mt-10 text-xl md:text-2xl my-2 md:my-4 text-[#696969] font-bold">
          National Cadet Corps
        </h2>
        <p className="text-black text-center my-1 text-md md:text-lg dark:text-white">
          (Sub Unit of PB BN NCC Nabha with the strength of 104 Cadets (Boys and
          Girls) <br /> ANO -- Lieutenent (Dr. K.K.Sharma)
        </p>
        <h3 className="mt-10 text-xl md:text-2xl my-2 md:my-4 text-[#696969] font-bold">
          Motto
        </h3>
        <p className="text-black my-1 text-md md:text-lg dark:text-white">
          UNITY AND DISCIPLINE.
        </p>
        <h4 className="mt-10 text-xl md:text-2xl my-2 md:my-4 text-[#696969] font-bold">
          Aim:
        </h4>
        <ul
          className="list-disc text-white my-5 text-md md:text-lg 
        bg-gradient-to-b from-primary to-primary2 dark:from-primary_dark dark:to-primary2_dark 
        rounded-md shadow-xl py-6 px-10 md:px-12 lg:px-16"
        >
          <li>
            To Develop Character, Comradeship, Discipline, Leadership, Secular
            Outlook, Spirit of Adventure, and Ideals of Selfless Service amongst
            the Youth of the Country.
          </li>
          <li>
            To Create a Human Resource of Organized, Trained and Motivated
            Youth, To Provide Leadership in all Walks of life and be Always
            Available for the Service of the Nation.
          </li>
          <li>
            To Provide a Suitable Environment to Motivate the Youth to Take Up a
            Career in the Armed Forces.
          </li>
        </ul>
        <img
          src="./ncc1.jpg"
          alt=""
          className=" mt-5 m-auto w-[50vw] max-w-[1080px]"
        />
        <img
          src="./ncc2.jpg"
          alt=""
          className=" mt-5 m-auto w-[50vw] max-w-[1080px]"
        />
        <img
          src="./ncc3.jpg"
          alt=""
          className=" mt-5 m-auto w-[50vw] max-w-[1080px]"
        />
        <img
          src="./ncc4.jpg"
          alt=""
          className=" mt-5 m-auto w-[50vw] max-w-[1080px]"
        />
        <img
          src="./ncc5.jpg"
          alt=""
          className=" mt-5 m-auto w-[50vw] max-w-[1080px]"
        />
        <img
          src="./ncc6.jpg"
          alt=""
          className=" mt-5 m-auto w-[50vw] max-w-[1080px]"
        />
      </div>
    </div>
  );
}
